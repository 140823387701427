/**
 * Takes a camel case string and returns a more human-readable string.
 *
 * @param   {String}    camelCaseString    The camel cased string to beautify.
 * @returns {String}                       The human-readable string.
 */
 export const beautifyCamelCase = (camelCaseString) => {
  const splitString = camelCaseString.split(/(?=[A-Z])/); // split type on capital letters
  splitString[0] = splitString[0].charAt(0).toUpperCase() + splitString[0].slice(1); // capitalize the first letter
  return splitString.join(' '); // join capitalized words together
};

export function randomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  while (result.length < length) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

export function isObject(item) {
  return typeof item === 'object' && item !== null && !Array.isArray(item);
}

export function recursivelyCloneArray(arr) {
  return arr.slice(0).map((item) => {
    if (isObject(item)) {
      return recursivelyCloneObject(item);
    }

    if (Array.isArray(item)) {
      return recursivelyCloneArray(item);
    }

    return item;
  });
}

export function recursivelyCloneObject(obj) {
  const clonedObj = {};

  for (let key in obj) {
    if (isObject(obj[key])) {
      clonedObj[key] = recursivelyCloneObject(obj[key]);

      continue;
    }

    if (Array.isArray(obj[key])) {
      clonedObj[key] = recursivelyCloneArray(obj[key]);

      continue;
    }

    clonedObj[key] = obj[key];
  }

  return clonedObj;
}

export function debounce (fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const urlRegex = /^((https?):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
