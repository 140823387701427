import optimizelySDK from '@optimizely/optimizely-sdk';

export default class Optimizely {
  constructor(userId = null) {
    optimizelySDK.setLogLevel(process.env.NODE_ENV === 'production' ? null : 'info'); //Change info to debug if you need more debugging information
    this.optimizelyClientInstance = optimizelySDK.createInstance({
      sdkKey: process.env.VUE_APP_OPTIMIZELY_SDK_KEY || '',
    });
    this._userId = userId ? userId.toString() : null;
  }

  get userId() {
    return this._userId;
  }

  set userId(userId) {
    this._userId = userId ? userId.toString() : null;
  }

  get ready() {
    return this.optimizelyClientInstance && this.userId;
  }

  isFeatureEnabled(featureKey) {
    return this.optimizelyClientInstance.isFeatureEnabled(featureKey, this.userId);
  }

  getFeatureVariable(featureKey, key) {
    if (!this.isFeatureEnabled(featureKey)) return null;
    return this.optimizelyClientInstance.getFeatureVariable(featureKey, key, this.userId);
  }
}
