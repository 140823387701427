import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1C7ABF',
        secondary: '#DDEAF7',
        accent: '#214286',
        error: '#EE5343',
        warning: '#F9DBCC',
        info: '#DDEAF7',
        success: '#91CD9C',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
