var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.loading
        ? _c(
            "div",
            [
              _c("v-progress-linear", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.show
        ? _c(
            _vm.layout,
            {
              tag: "component",
              attrs: { "menu-items": _vm.menuItems, logo: _vm.logo }
            },
            [_c("router-view", { key: _vm.key })],
            1
          )
        : _vm._e(),
      _vm._l(_vm.notifications, function(notification) {
        return _c(
          "v-snackbar",
          {
            key: notification.id,
            attrs: {
              color: notification.color,
              timeout: notification.timeout,
              value: notification.message
            },
            on: {
              input: function() {
                return _vm.clearNotification(notification)
              }
            }
          },
          [_vm._v(" " + _vm._s(notification.message) + " ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }