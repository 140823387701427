import Vue from 'vue';
import VueRouter from 'vue-router';
import jwt_decode from 'jwt-decode';
import store from '../store/index';
import reportsRoutes from './reports';

Vue.use(VueRouter);

const routes = [  
  {
    path: '/',
    name: 'Create Proposal',
    meta: {
      title: 'Proposal',
      layout: 'no-sidebar',
    },
    component: () => import('../domains/proposals/views/Index.vue'),
  },
  {
    path: '/proposals/new/:id',
    name: 'Create Proposal with ID',
    meta: {
      title: 'Proposal',
      layout: 'no-sidebar',
    },
    component: () => import('../domains/proposals/views/Index.vue'),
  },
  {
    path: '/coupons',
    name: 'E-Commerce Settings > Coupons',
    meta: {
      title: 'E-Commerce Settings > Coupons',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "couponsIndex" */ '../views/coupons/Index.vue'),
  },
  ...reportsRoutes,
  {
    path: '/c2-freemium-proposal-public/:proposal_slug',
    name: 'Proposal-old',
    meta: {
      title: 'Proposal',
      layout: 'no-sidebar',
    },
    component: () => import(/* webpackChunkName: "proposalIndex" */ '../domains/proposals/views/Index.vue'),
  },
  {
    path: '/proposals/new',
    redirect: `/proposals/new/${process.env.VUE_APP_DEFAULT_PROPOSAL_TEMPLATE}`,
  },
  {
    path: '/proposals/:id',
    name: 'Proposal',
    meta: {
      title: 'Proposal',
      layout: 'no-sidebar',
    },
    component: () => import('../domains/proposals/views/Index.vue'),
  },
  {
    path: '/proposals/:id/send-email',
    name: 'Send Proposal Email',
    meta: {
      title: 'Proposal',
      layout: 'no-sidebar',
    },
    props: {
      showSendEmailUi: true,
    },
    component: () => import('../domains/proposals/views/Index.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('setLoading', true);
  document.title = `${to.meta.title} - Curate` || 'Curate';

  const token = localStorage.getItem('curate_token');
  const currentSlug = window.location.host.split('.')[0];

  if (token) {
    const decoded = jwt_decode(token);
    if (currentSlug !== decoded.company.slug) {
      // You are currently logged in to a different subdomain. Remove the token on this subdomain and redirect to the correct location.
      localStorage.removeItem('curate_token');

      let url = window.location.href.replace(currentSlug, decoded.company.slug);
      if (to && to.fullPath) {
        // When redirecting, use the target path
        url = window.location.origin.replace(currentSlug, decoded.company.slug);
        url += '/' + to.fullPath.substr(1);// don't include the preceeding slash
      }

      window.location.href = url;
    }
  }

  return store.dispatch('auth/authenticate').then(() => {
    return next();
  });
});

router.afterEach(() => {
  store.commit('setLoading', false);
});

export default router;
