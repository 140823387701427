export default {
  state: {
    title: '',
    subtitle: '',
    btnSaveText: 'Done',
    props: {},
    visible: false,
    component: null,
  },
  mutations: {
    setTitle: (state, title) => state.title = title,
    setSubtitle: (state, subtitle) => state.subtitle = subtitle,
    setSaveBtnText: (state, btnSaveText) => state.btnSaveText = btnSaveText,
    setProps: (state, props) => state.props = props,
    setVisibility: (state, visibility) => state.visible = visibility,
    setComponent: (state, component) => state.component = component,
  },
  actions: {
    open({ commit }, { title, subtitle = '', saveBtnText = 'Save', props = {}, component }) {
      commit('setTitle', title);
      commit('setSubtitle', subtitle);
      commit('setSaveBtnText', saveBtnText);
      commit('setProps', props);
      commit('setComponent', component);
      commit('setVisibility', true);
    },
  },
};
