<template>
  <v-main>
    <v-container>
      <slot />
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'NoSidebar',

  props: {
    menuItems: {
      type: Array,
      required: false,
    },
    logo: {
      type: Array,
      required: false,
    },
  },
};
</script>
