<template>
  <div>
    <v-navigation-drawer style="background-color:#e9e8e8; color:#767676" permanent app width="200" bottom>
      <v-list>
        <v-list-item class="px-2">
          <v-img
              contain
              :max-height="logo[2]"
              :src="logo[0]"
          ></v-img>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
            v-for="item in menuItems"
            :key="item.label"
            :href="item.url"
            style="color:#767676; padding-top:4px; padding-bottom:4px;"
        >

          <v-list-item-icon style="margin-right:10px;color:#767676;">
            <v-icon class="fa-fw" style="font-size:22px">fa-{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-badge v-if="item.pill_number"
                     color="light-blue lighten-2"
                     :content="item.pill_number"
                     :label="item.pill"
                     :overlap="true"
                     offset-x="25"
                     offset-y="18"
            >
              <v-list-item-title class="text-subtitle-1 font-weight-bold" style="color:#767676; font-family:'Open Sans Condensed', Helvetica, sans-serif !important;">{{ item.label.toUpperCase() }}</v-list-item-title>
            </v-badge>

            <v-list-item-title v-if="!item.pill_number" class="text-subtitle-1 font-weight-bold" style="color:#767676; font-family:'Open Sans Condensed', Helvetica, sans-serif !important;">{{ item.label.toUpperCase() }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <slot />
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'Default',

  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    logo: {
      type: Array,
      required: true,
    },
  },
};
</script>
