import apolloProvider from '../vue-apollo';
import EVENT_CREATE from '../graphql/Events/EventCreate.gql';
import EVENT_EDIT from '../graphql/Events/EventEdit.gql';
import SEND_EVENT_EMAIL from '../graphql/Events/SendEventEmail.gql';
import Vue from 'vue';
import router from '@/router/index';
import { randomString } from '@/utils/misc';
import { generateLineItem } from '@/domains/proposals/utils/line-items';

const apollo = apolloProvider.defaultClient;

export default {
  state: {
    loading: true,
    current: null,
    currentChanged: false,
  },
  mutations: {
    setCurrentChanged(state, changed) {
      state.currentChanged = changed;
    },
    setCurrent(state, event) {
      if (!event) {
        return;
      }

      event.tabs.forEach((tab) => {
        tab.containers.forEach((container) => {
          container.data = JSON.parse(container.data);
          container.options = JSON.parse(container.options);
        });
      });

      let taxableSubtotal = 0;
      const subtotal = event.arrangements.reduce((subtotal, item) => {
        if (item.include) {
          subtotal += item.override_cost * item.quantity;
          taxableSubtotal += item.tax ? item.override_cost * item.quantity : 0;
        }

        return subtotal;
      }, 0);

      const tax = taxableSubtotal * (event.tax_rate_value / 100);
      const tax2 = event.tax_rate_value2
        ? taxableSubtotal * (event.tax_rate_value2 / 100)
        : 0;

      event.event_cost = {
        cc_fees: 0,
        subtotal: subtotal,
        tax: tax,
        tax2: tax2,
        tax_rate_id: event.tax_rate_id,
        tax_rate_value: event.tax_rate_value,
        tax_rate_id2: event.tax_rate_id2,
        tax_rate_value2: event.tax_rate_value2,
        total: subtotal + tax + tax2,
        show_both_rates: false,
      };

      state.current = event;

      state.loading = false;
    },
    addLineItem(state, lineItem = {}) {
      const newLineItem = generateLineItem(lineItem);

      if (!newLineItem.id) {
        newLineItem.id = 'new-' + randomString(8);
      }

      state.current.arrangements.push(newLineItem);
    },
    updateLineItem(state, lineItem) {
      const keys = Object.keys(lineItem);
      const existingLineItem = state.current.arrangements.find((arr) => {
        return arr.id === lineItem.id;
      });

      for (const key of keys) {
        // If the key does not exist in the vuex object, we need to add it reactively
        if (!(key in existingLineItem)) {
          Vue.set(existingLineItem, key, lineItem[key]);
          continue;
        }

        existingLineItem[key] = lineItem[key];
      }
    },
    setHeaderPhoto(state, photo) {
      state.current.tabs.forEach((tab) => {
        tab.containers.forEach((container) => {
          if (container.container_type === 'header') {
            container.data = {
              cover: {
                url: photo.url,
              },
            };            
          } 
        });    
      });

    },
    isLoading(state) {
      state.loading = true;
    },
    updateCustomer(state, { index, customer }) {
      const keys = [
        'first_name',
        'last_name',
        'email',
        'phone',
        'address',
        'geocode_address',
      ];

      const customerState = state.current.customers[index];

      for (const key of keys) {
        // If the key does not exist in the vuex object, we need to add it reactively
        if (!(key in customerState)) {
          Vue.set(customerState, key, customer[key]);

          continue;
        }

        // If the key already exists, all good. Add normally
        if (key in customer) {
          customerState[key] = customer[key];
        }
      }

      customerState.full_name = `${customerState.first_name} ${customerState.last_name}`;
    },
    updateField(state, { field, value }) {
      state.current[field] = value;
    },
    updateFields(state, fieldsToUpdate) {
      Object.keys(fieldsToUpdate).forEach(key => {
        state.current[key] = fieldsToUpdate[key];
      });
    },
    deleteLineItem: (state, id) => {
      const index = state.current.arrangements.findIndex(arrangement => arrangement.id === id);
      state.current.arrangements.splice(index, 1);
    },
    updateCompany(state, company) {
      state.current.company = company;
    },
    updateOwnerEmail(state, email) {
      state.current.owner.user_email = email;
    },
    recalculatePricing(state) {
      const proposal = state.current;
      const arrangements = proposal.arrangements;

      const pricing = {
        ...proposal.event_cost,
        tax_rate_id: proposal.tax_rate_id,
        tax_rate_id2: proposal.tax_rate_id2,
        tax_rate_value: proposal.tax_rate_value,
        tax_rate_value2: proposal.tax_rate_value2,
      };

      let taxableSubtotal = 0;
      pricing.subtotal = arrangements.reduce((subtotal, item) => {
        if (item.include) {
          subtotal += item.override_cost * item.quantity;
          taxableSubtotal += item.tax ? item.override_cost * item.quantity : 0;
        }

        return subtotal;
      }, 0);

      pricing.tax = taxableSubtotal * (pricing.tax_rate_value / 100);
      pricing.tax2 = 0;

      if (pricing.tax_rate_value2) {
        pricing.tax2 = taxableSubtotal * (pricing.tax_rate_value2 / 100);
      }

      pricing.total = pricing.subtotal + pricing.tax + pricing.tax2;

      state.current.event_cost = pricing;
    },
  },

  actions: {
    save({ commit, state }) {
      let data = JSON.parse(
        JSON.stringify(state.current).replace(/__typename/gi, 'typename'),
      ); //Easiest way to get rid of the default typename that comes with graphql
      data.tabs.forEach((element) => {
        //Im real sorry for all of this, but graphql limitations with json....
        element.containers.forEach((container) => {
          container.data = JSON.stringify(container.data);
          container.options = JSON.stringify(container.options);
        });
      });
      data.arrangements.forEach(arrangement => {
        arrangement.quantity = parseFloat(arrangement.quantity);
        arrangement.override_cost = parseFloat(arrangement.override_cost);
      });
      data.customers.forEach((customer) => {
        if (customer.geocode_address) {
          customer.address = customer.geocode_address.name;
          customer.address_lat = customer.geocode_address.latitude;
          customer.address_lng = customer.geocode_address.longitude;
          customer.address_street_number =
            customer.geocode_address.street_number;
          customer.address_route = customer.geocode_address.route;
          customer.address_locality = customer.geocode_address.locality;
          customer.address_postal_code = customer.geocode_address.postal_code;
          customer.address_area_1 =
            customer.geocode_address.administrative_area_level_1;
          customer.address_area_2 =
            customer.geocode_address.administrative_area_level_2;
          customer.address_country = customer.geocode_address.country;
        }
        delete customer.geocode_address;
      });
      delete data.company.logo;
      delete data.company.slug;
      delete data.event_cost;

      let isCreating = router.currentRoute.name.includes('Create Proposal');

      if (isCreating) {
        delete data.id;
      }

      return new Promise((resolve, reject) => {
        apollo.mutate({
          mutation: isCreating ? EVENT_CREATE : EVENT_EDIT,
          variables: {
            input: data,
          },
        }).then(data => {
          commit('setCurrentChanged', false);
          resolve({ data, isCreating });
        }).catch((err) => {
          console.log(err);
          reject(err);
        });
      });
    },
    async sendProposalEmail({ state }, { email, subject, content, cc = '', buttonText, onError = () => { }, onSuccess = () => { } }) {
      const eventId = state.current.id;
      let response;

      const variables = {
        eventId, email, subject, content, buttonText,
      };

      if (cc !== '') {
        variables.cc = cc;
      }

      try {
        response = await apollo.mutate({
          mutation: SEND_EVENT_EMAIL,
          variables: variables,
        });
      } catch (e) {
        return onError(e.message);
      }

      if (!response.data.status) {
        return onError('There was an error sending the email.');
      }

      return onSuccess('Email sent');
    },
  },
  modules: {},
};
