/**
 * @param {String} sectionType
 * @return {*}
 */
export function getSectionConfig (sectionType) {
  return sectionConfigs[sectionType];
}

export const sectionConfigs = {
  standard: {
    key: 'standard',
    showItem: true,
    showItemPrice: true,
    showItemQuantity: true,
    showItemPhotos: true,
    showHeader: true,
    showHeaderPrice: false,
  },
  hide_items: {
    key: 'hide_items',
    showItem: false,
    showItemPrice: true,
    showItemQuantity: true,
    showItemPhotos: true,
    showHeader: true,
    showHeaderPrice: true,
  },
  only_section: {
    key: 'only_section',
    showItem: false,
    showItemPrice: true,
    showItemQuantity: true,
    showItemPhotos: true,
    showHeader: true,
    showHeaderPrice: false,
  },
  hide_prices: {
    key: 'hide_prices',
    showItem: true,
    showItemPrice: false,
    showItemQuantity: true,
    showItemPhotos: true,
    showHeader: true,
    showHeaderPrice: true,
  },
  hide_all_prices: {
    key: 'hide_all_prices',
    showItem: true,
    showItemPrice: false,
    showItemQuantity: true,
    showItemPhotos: true,
    showHeader: true,
    showHeaderPrice: false,
  },
  show_all_prices: {
    key: 'show_all_prices',
    showItem: true,
    showItemPrice: true,
    showItemQuantity: true,
    showItemPhotos: true,
    showHeader: true,
    showHeaderPrice: true,
  },
  no_section: {
    key: 'no_section',
    showItem: false,
    showItemPrice: true,
    showItemQuantity: true,
    showItemPhotos: true,
    showHeader: false,
    showHeaderPrice: false,
  },
  centered_items: {
    key: 'centered_items',
    showItem: true,
    showItemPrice: false,
    showItemQuantity: false,
    showItemPhotos: false,
    showHeader: true,
    showHeaderPrice: false,
  },
};

/**
 * @param {Object} lineItem
 * @param {Object|null} section
 * @return {boolean}
 */
export function shouldShowItemQuantities(lineItem, section) {
  if (null !== lineItem.header_type) {
    return false;
  }

  if ( lineItem.is_percentage ) {
    return false;
  }

  if ( section && parseInt( section.hide_item_quantities, 10 ) ) {
    return false;
  }

  const sectionConfig = null !== section ? getSectionConfig(section.header_type) : false;
  if ( sectionConfig && ! sectionConfig.showItemQuantity ) {
    return false;
  }

  return true;
}

/**
 * @param {Object} lineItem
 * @param {Object|null} section
 * @return {boolean}
 */
export function shouldShowItemPrice(lineItem, section) {
  if ( null !== lineItem.header_type ) {
    return false;
  }

  if ( 'free' === lineItem.calculation_type ) {
    return false;
  }

  if ( section && parseInt( section.hide_item_prices, 10 ) ) {
    return false;
  }

  const sectionConfig = null !== section ? getSectionConfig(section.header_type) : false;
  if ( sectionConfig && !sectionConfig.showItemPrice ) {
    return false;
  }

  return true;
}

/**
 * @param {Object} lineItem
 * @return {boolean}
 */
export function shouldShowSectionPrice(lineItem) {
  if (null === lineItem.header_type) {
    return false;
  }

  const sectionConfig = getSectionConfig(lineItem.header_type);

  if (!sectionConfig) {
    return true;
  }

  if (!sectionConfig.showHeaderPrice) {
    return false;
  }

  return true;
}

/**
 * @param {Object} lineItem
 * @param {Array} lineItems
 * @return {null}
 */
export function getParent(lineItem, lineItems) {
  let parent = null;
  let foundThisItem = false;

  if ( null !== lineItem.header_type ) {
    return null;
  }

  for (let lineItemToCheck of lineItems) {
    if (foundThisItem) {
      return parent;
    }

    if ( ! parseInt( lineItemToCheck.include, 10 ) ) {
      continue;
    }

    if ( parseInt( lineItemToCheck.addon, 10 ) !== parseInt( lineItem.addon, 10 ) ) {
      continue;
    }

    // We have found the current lineItem.
    if (lineItemToCheck.id === lineItem.id) {
      foundThisItem = true;
      continue;
    }

    if (lineItemToCheck.header_type && lineItemToCheck.header_type !== 'no_section') {
      parent = lineItemToCheck;
      continue;
    }

    if (lineItemToCheck.header_type && lineItemToCheck.header_type === 'no_section') {
      parent = null;
    }
  }

  return parent;
}

export function getParentSectionConfig(lineItem, lineItems) {
  const parent = getParent(lineItem, lineItems);
  if (!parent) {
    return null;
  }

  return getSectionConfig(parent.header_type);
}

/**
 * @param {Object} section
 * @param {Array} lineItems
 */
export function getSectionLineItems(section, lineItems) {
  let children = [];
  let foundThisItem = false;

  if ( null === section.header_type ) {
    return [];
  }

  if (section.header_type === 'no_section') {
    return [];
  }

  for ( let lineItem of lineItems ) {
    if ( ! parseInt( lineItem.include, 10 ) ) {
      continue;
    }

    if ( parseInt( lineItem.addon, 10 ) !== parseInt( section.addon, 10 ) ) {
      continue;
    }

    // We have gone through all the line items between this lineItem and the next
    if (!!lineItem.header_type && foundThisItem) {
      break;
    }

    // We have found the current lineItem.
    if (!!lineItem.header_type && lineItem.id === section.id) {
      foundThisItem = true;
      continue;
    }

    if ( foundThisItem ) {
      children.push( lineItem );
    }
  }

  return children;
}

export function shouldShowItemPhotos(lineItem, lineItems) {
  if ( null !== lineItem.header_type ) {
    return false;
  }

  const parentSectionConfig = getParentSectionConfig(lineItem, lineItems);
  if ( parentSectionConfig && ! parentSectionConfig.showItemPhotos ) {
    return false;
  }

  return true;
}

export function generateLineItem(overrides = {}) {
  const baseProps = {
    addon: 0,
    header_type: null,
    id: null,
    include: 1,
    is_percentage: null,
    name: '',
    note: '',
    order: 0,
    override_cost: 0,
    photos: [],
    quantity: 1,
    tax: true,
    typename: 'Arrangement',
  };

  return {
    ...baseProps,
    ...overrides,
  };
}
