import Vue from 'vue';
import Vuex from 'vuex';
import AuthModule from './auth';
import ProposalModule from './proposal';
import SlideoutModule from './slideout';
import { randomString } from '@/utils/misc';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    notifications: [],
    loading: true,
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    notify(state, { message, timeout = -1, color = null }) {
      const id = randomString(8);

      state.notifications.push({ id, message, timeout, color });
    },
    clearNotification(state, id) {
      const notification = state.notifications.find(notif => notif.id === Number(id));

      state.notifications.splice(state.notifications.indexOf(notification), 1);
    },
  },
  actions: {
  },
  modules: {
    auth: AuthModule,
    proposals: ProposalModule,
    slideout: SlideoutModule,
  },
});
