import Vue from 'vue';
import dayjs from 'dayjs';

Vue.filter('date', function(value) {
    if (value) {
        return dayjs(String(value)).format('MM/DD/YYYY');
    }
});

Vue.filter('dateTime', function(value) {
    if (value) {
        return dayjs(String(value)).format('MM/DD/YYYY hh:mm a');
    }
});

Vue.filter('properCase', function(value) {
    if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
});

Vue.filter('stripHtml', function (value) {
    var div = document.createElement('div');
    div.innerHTML = value;
    var text = div.textContent || div.innerText || '';
    return text;
});

Vue.filter('currency', value => {
    if (value || value === 0) {
        return `$${value}`;
    }
});

