var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "background-color": "#e9e8e8", color: "#767676" },
          attrs: { permanent: "", app: "", width: "200", bottom: "" }
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { staticClass: "px-2" },
                [
                  _c("v-img", {
                    attrs: {
                      contain: "",
                      "max-height": _vm.logo[2],
                      src: _vm.logo[0]
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.menuItems, function(item) {
              return _c(
                "v-list-item",
                {
                  key: item.label,
                  staticStyle: {
                    color: "#767676",
                    "padding-top": "4px",
                    "padding-bottom": "4px"
                  },
                  attrs: { href: item.url }
                },
                [
                  _c(
                    "v-list-item-icon",
                    {
                      staticStyle: { "margin-right": "10px", color: "#767676" }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "fa-fw",
                          staticStyle: { "font-size": "22px" }
                        },
                        [_vm._v("fa-" + _vm._s(item.icon))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      item.pill_number
                        ? _c(
                            "v-badge",
                            {
                              attrs: {
                                color: "light-blue lighten-2",
                                content: item.pill_number,
                                label: item.pill,
                                overlap: true,
                                "offset-x": "25",
                                "offset-y": "18"
                              }
                            },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-subtitle-1 font-weight-bold",
                                  staticStyle: {
                                    color: "#767676",
                                    "font-family":
                                      "'Open Sans Condensed', Helvetica, sans-serif !important"
                                  }
                                },
                                [_vm._v(_vm._s(item.label.toUpperCase()))]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !item.pill_number
                        ? _c(
                            "v-list-item-title",
                            {
                              staticClass: "text-subtitle-1 font-weight-bold",
                              staticStyle: {
                                color: "#767676",
                                "font-family":
                                  "'Open Sans Condensed', Helvetica, sans-serif !important"
                              }
                            },
                            [_vm._v(_vm._s(item.label.toUpperCase()))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("v-main", [_c("v-container", [_vm._t("default")], 2)], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }