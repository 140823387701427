export default [
  {
    path: '/reports/rentals',
    name: 'Rentals Range Report',
    meta: {
      title: 'Rentals Range Report',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rentalsRentalsRange" */ '../views/reports/RentalsRange.vue'),
  },
  { path: '/reports*', redirect: '/reports/rentals' }, // ! Temporary rule to just go to the Rental Range report by default
];